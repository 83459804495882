
<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          dense
          type="number"
          v-model="cp"
          :counter="5"
          label="Código Postal"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <model-list-select
          outlined
          dense
          :list="calles"
          v-model="calleSeleccionada"
          option-value="nombre"
          option-text="nombre"
          class="form-datos-cliente-border"
          placeholder="Calle"
          @select="onSelectCalle"
          :isDisabled="this.isDisabledList"
        >
        </model-list-select>
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          outlined
          dense
          v-model="selectNumeroCalle"
          :items="arrayNumerosCalles"
          item-text="valor"
          item-value="valor"
          label="Número"
          return-object
          @change="onSelectNumeroCalle"
          :disabled="this.bloquearSelectorNumero"
        ></v-select>
      </v-col>
    </v-row>

    <v-row v-show="this.ocultarBotonCalleNumeroFormulario">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="arrayDirecciones"
          item-key="name"
          class="elevation-1"
          no-data-text="No hay cobertura Ftth en esa dirección."
          data-test="vDatatable"
          multi-sort
          :footer-props="{
            'items-per-page-text': 'Elementos por página:',
            pageText: '{1} de {2}',
            'items-per-page-all-text': 'Todos',
          }"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              color="primary"
              fab
              icon
              v-show="!item.marcado"
              class="mr-2"
              @click="ver(item)"
            >
              mdi-plus
            </v-icon>
            <v-icon
              color="red"
              v-show="item.marcado"
              fab
              icon
              @click="borrar()"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-show="this.ocultarBotonCalleNumeroFormulario && this.verForm">
      <v-col cols="12" sm="12">
        <v-select
          outlined
          dense
          v-model="subscriberType"
          :items="tipoCliente"
          item-text="tipo"
          item-value="id"
          label="Tipo de cliente"
          return-object
        ></v-select>
      </v-col>
    </v-row>

    <v-row v-show="ShowcontactFiscalId && this.verForm">
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          dense
          v-model="empresa"
          v-show="ShowcontactFiscalId"
          label="Empresa"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          dense
          v-show="ShowcontactFiscalId"
          v-model="contactFiscalId"
          label="CIF empresa"
          :rules="rulescif"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="this.ocultarBotonCalleNumeroFormulario && this.verForm">
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          dense
          v-model="nombre"
          label="Nombre"
          :rules="rulesTextSimple"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          dense
          v-model="apellidos"
          label="Apellidos"
          :rules="rulesTextSimple"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="this.ocultarBotonCalleNumeroFormulario && this.verForm">
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          dense
          v-model="email"
          :rules="rulesCorreo"
          label="Email"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          dense
          v-model="movil"
          :rules="rulesMovil"
          label="Teléfono móvil"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="this.ocultarBotonCalleNumeroFormulario && this.verForm">
      <v-col cols="12" sm="6">
        <v-text-field
          outlined
          dense
          v-model="fiscalId"
          label="DNI"
          :rules="rulesFiscalId"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <v-menu
          :close-on-content-click="false"
          ref="dialog"
          :return-value.sync="fecha_nacimiento"
          persistent
          width="290px"
          v-model="menu"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              dense
              v-model="fecha_nacimiento_formulario"
              label="Fecha de nacimiento"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fecha_nacimiento"
            :max="
              new Date(Date.now() - 567648000000).toISOString().substr(0, 10)
            "
            min="1920-01-01"
            :active-picker.sync="activePicker"
            scrollable
            :first-day-of-week="1"
            locale="es-es"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancelar </v-btn>
            <v-btn
              text
              color="primary"
              @click="seleccionarFechaNacimiento()"
              :disabled="this.bloquearBotonGardarFechaNacemento"
            >
              Guardar
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row v-show="this.barraLoading">
      <v-col>
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-col>
    </v-row>

    <v-row v-show="this.ocultarBotonCalleNumeroFormulario && this.verForm">
      <v-col cols="12" sm="12">
        <v-checkbox
          label="Misma dirección de facturación"
          color="primary"
          value
          input-value="true"
          @click="checkbox()"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-show="!checkboxValue">
      <v-col cols="12" sm="12">
        <v-text-field
          outlined
          dense
          v-model="direccionFacturacionDireccion"
          label="Dirección"
          :rules="rulesTextSimple"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          dense
          v-model="direccionFacturacionPoblacion"
          label="Población"
          :rules="rulesTextSimple"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          dense
          v-model="direccionFacturacionProvincia"
          label="Provincia"
          :rules="rulesTextSimple"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          outlined
          dense
          type="number"
          v-model="direccionFacturacionCp"
          label="Código Postal"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="this.ocultarBotonCalleNumeroFormulario && this.verForm">
      <v-col cols="12">
        <v-text-field
          outlined
          dense
          v-model="numeroCuenta"
          label="Número de cuenta para domiciliación"
          required
          :rules="rulesIBAN"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :right="true"
      :timeout="snack_time"
      :color="snack_color"
    >
      {{ snack_text }}
      <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { uuid } from 'vue-uuid'
import { ModelListSelect } from 'vue-search-select'

export default {
    components: {
        ModelListSelect
    },
    data () {
        return {
            tipoCliente: [
                { tipo: 'Particular', id: 0 },
                { tipo: 'Empresa', id: 1 }
            ],
            espera: 6000,
            date: null,
            menu: false,
            tipoVial: '',
            isDisabledList: true,
            activePicker: null,
            checkboxValue: true,
            direccionFacturacionProvincia: '',
            direccionFacturacionPoblacion: '',
            direccionFacturacionCp: '',
            direccionFacturacionDireccion: '',
            modal: false,
            subscriberType: '',
            documentType: '',
            files: [],
            empresa: '',
            fiscalId: '',
            contactFiscalId: '',
            ShowcontactFiscalId: false,
            nombre: '',
            apellidos: '',
            cp: '',
            poblacion: '',
            provincia: 'Lugo',
            email: '',
            movil: '',
            fecha_nacimiento: '',
            fecha_nacimiento_formulario: '',
            nacionalidad: 'Española',
            numeroCuenta: '',
            file: null,
            snackbar: false,
            snack_color: '',
            snack_text: '',
            snack_time: -1,
            url: process.env.VUE_APP_API_URL_FIBRA,
            selectPuerta: '',
            selectEscalera: '',
            selectPlanta: '',
            selectNumeroCalle: '',
            ocultarBotonCalleNumeroFormulario: false,
            bloquearSelectorNumero: true,
            bloquearBotonGardarFechaNacemento: false,
            verForm: false,
            calles: [],
            arrayNumerosCalles: [],
            arrayDirecciones: [],
            arrayPuertas: [],
            calleSeleccionada: {},
            apartment_id: '',
            barraLoading: false,
            borradoPulsado: false,
            rulesTextSimple: [
                (v) =>
                    /^([A-Za-z0-9-,.àèìòùÀÈÌÒÙáéíóúÁÉÍÓÚñÑëü\s])*$/.test(v) ||
          'Carácteres inválidos.',
                (v) => !!v || 'Campo obligatorio.'
            ],
            rulesCorreo: [
                (v) =>
                    /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@"]{2,})$/.test(
                        v
                    ) || 'Introduzca una dirección de correo electrónico.'
            ],
            rulesMovil: [
                (v) =>
                    /^(\+34|0034|34)?[ .-]*(6|7|8|9)[ .-]*(\d[ .-]*){8}$/.test(v) ||
          'Introduzca un número de móvil.'
            ],

            rulesFiscalId: [
                (v) =>
                    /^[0-9]{8}[a-zA-Z]$/.test(v) || 'Introduzca el número de su DNI.'
            ],
            rulescif: [
                (v) =>
                    /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/.test(v) ||
          'Introduzca el número del CIF.'
            ],
            rulesIBAN: [
                (v) =>
                    /^([A-Z]{2}[ \]?[0-9]{2})(?=(?:[\]?[A-Z0-9]){20}$)((?:[\]?[A-Z0-9]{3,5}){2,7})([\]?[A-Z0-9]{1,3})?$/.test(
                        v
                    ) || 'IBAN incorrecto, ES004915000512345666xxxx'
            ],
            headers: [
                {
                    text: 'Municipio',
                    align: 'start',
                    sortable: false,
                    value: 'municipio'
                },
                {
                    text: 'Número',
                    align: 'start',
                    sortable: false,
                    value: 'numero'
                },
                {
                    text: 'Piso',
                    align: 'start',
                    sortable: false,
                    value: 'piso'
                },
                {
                    text: 'Puerta',
                    align: 'start',
                    sortable: false,
                    value: 'puerta'
                },
                {
                    text: 'Codigo servicio',
                    align: 'start',
                    sortable: false,
                    value: 'apartment_id'
                },

                { text: 'Acciones', align: 'right', value: 'action', sortable: false }
            ]
        }
    },
    computed: {
        comprobarEdad () {
            return this.fecha_nacimiento
        },
        CambiarCp () {
            return this.cp
        },
        SelectNumeroPuertaC () {
            return this.selectPuerta
        },

        CambioCalle () {
            return this.calleSeleccionada
        },

        canGuardar () {
            const patterCorreo =
        /^(([^<>()[\],;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(.+))@(([^<>()[\],;:\s@]+\.)+[^<>()[\],;:\s@"]{2,})$/
            //   const patternMovil = /^\+?[6-7]{1}-?[0-9]{8}$/
            const patternMovil =
        /^(\+34|0034|34)?[ .-]*(6|7|8|9)[ .-]*(\d[ .-]*){8}$/

            const patterDNI = /^[0-9]{8}[A-Z]$/
            const patternTextoSimple =
        /^([A-Za-z0-9-,.àèìòùÀÈÌÒÙáéíóúÁÉÍÓÚñÑëü\s])*$/
            const patternCIF = /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/
            var IBAN = require('iban')
            if (
                this.subscriberType.tipo !== '' &&
        this.documentType !== '' &&
        this.fecha_nacimiento !== '' &&
        (this.documentType !== 4 ||
          (this.documentType === 4 &&
            this.empresa !== '' &&
            patternTextoSimple.test(this.empresa) &&
            this.contactFiscalId !== '' &&
            patternCIF.test(this.contactFiscalId))) &&
        this.email !== '' &&
        this.nombre !== '' &&
        patternTextoSimple.test(this.nombre) &&
        this.apellidos !== '' &&
        patternTextoSimple.test(this.apellidos) &&
        this.movil !== '' &&
        this.calleSeleccionada !== '' &&
        this.arrayPuertas.length > 0 &&
        this.provincia !== '' &&
        patternTextoSimple.test(this.provincia) &&
        this.poblacion !== '' &&
        this.numeroCuenta !== '' &&
        patterCorreo.test(this.email) &&
        this.apartment_id !== '' &&
        patternMovil.test(this.movil) &&
        IBAN.isValid(this.numeroCuenta) &&
        (patterDNI.test(this.fiscalId) || patternCIF.test(this.fiscalId)) &&
        (this.checkboxValue ||
          (!this.checkboxValue &&
            this.direccionFacturacionProvincia !== '' &&
            this.direccionFacturacionPoblacion !== '' &&
            this.direccionFacturacionCp !== '' &&
            this.direccionFacturacionDireccion !== ''))
            ) {
                return true
            } else {
                return false
            }
        },
        VtipoCliente () {
            return this.subscriberType
        }
    },
    watch: {
        menu (val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        SelectNumeroPuertaC () {
            this.onSelectNumeroPuerta()
        },
        CambiarCp (value) {
            this.comprobarCP()
        },
        CambioCalle (value) {
            this.onSelectCalle(value)
        },
        comprobarEdad (value) {
            this.calcularEdad(value)
        },
        VtipoCliente (value) {
            if (value.id === 1) {
                this.ShowcontactFiscalId = true
                this.documentType = 4
            }
            if (value.id === 0) {
                this.ShowcontactFiscalId = false
                this.empresa = ''
                this.documentType = 0
                this.contactFiscalId = ''
            }
        },
        canGuardar (value) {
            if (value) {
                this.enviarFormulario()
            } else {
                this.$store.dispatch('setdatosClienteValidados', value)
            }
        }
    },
    methods: {
        borrar () {
            this.arrayDirecciones.forEach((element) => {
                element.marcado = false
            })

            const intermedio = this.arrayDirecciones
            this.arrayDirecciones = []
            this.arrayDirecciones = intermedio
            this.selectPuerta = ''
            this.selectEscalera = ''
            this.selectPlanta = ''
            this.apartment_id = ''
            this.verForm = false
            this.borradoPulsado = true
        },
        ver (value) {
            this.borradoPulsado = false

            this.verForm = true

            this.arrayDirecciones.forEach((element) => {
                element.marcado = false
            })
            const UpdateIndex = this.arrayDirecciones.indexOf(value)
            let intermedio = []
            this.arrayDirecciones[UpdateIndex].marcado = true
            this.selectPuerta = value.puerta
            this.selectEscalera = value.escalera
            this.selectPlanta = value.piso
            intermedio = this.arrayDirecciones
            this.arrayDirecciones = []
            this.arrayDirecciones = intermedio
            this.onSelectNumeroPlanta()
            this.onSelectNumeroPuerta()
        },
        update () {
            this.$forceUpdate()
        },
        checkbox () {
            if (this.checkboxValue) {
                this.checkboxValue = false
            } else {
                this.checkboxValue = true
            }
        },
        seleccionarFechaNacimiento () {
            const fechaFormateada = new Date(this.fecha_nacimiento)
            this.fecha_nacimiento_formulario = fechaFormateada.toLocaleDateString()
            this.$refs.dialog.save(this.fecha_nacimiento)
        },
        go (msg, color, time) {
            this.snack_time = time
            this.snack_text = msg
            this.snack_color = color
            this.snackbar = true
        },
        onSelectNumeroCalle () {
            this.arrayDirecciones = []
            this.selectPuerta = ''
            this.selectEscalera = ''
            this.selectPlanta = ''
            this.comprobarDireccion()
        },

        onSelectNumeroPlanta () {
            this.arrayPuertas = []
            this.arrayDirecciones.forEach((element) => {
                if (element.piso === this.selectPlanta) {
                    this.arrayPuertas.push(element)
                }
            })
        },
        onSelectNumeroPuerta () {
            this.comprobarCobertura()
        },

        onSelectCalle (calleSeleccionada) {
            this.arrayDirecciones = []
            this.selectPuerta = ''
            this.selectEscalera = ''
            this.selectPlanta = ''
            this.selectNumeroCalle = ''
            this.ocultarBotonCalleNumeroFormulario = false
            this.arrayNumerosCalles = []
            if (calleSeleccionada.nombre) {
                this.calleSeleccionada = calleSeleccionada
                this.arrayNumerosCalles = []
                if (this.calleSeleccionada.nombre) {
                    this.listarNumerosCalle()
                }
            } else {
                this.bloquearSelectorNumero = true
                this.selectNumeroCalle = ''
                this.ocultarBotonCalleNumeroFormulario = false
            }
        },
        listarNumerosCalle () {
            const poblacion = this.calleSeleccionada.poblacion
            this.bloquearSelectorNumero = false
            this.poblacion = this.calleSeleccionada.poblacion
                .trim()
                .replace(/\s+/g, '%20')
            const calle = this.calleSeleccionada.nombre.trim().replace(/\s+/g, '%20')
            const golistarNumerosCalle = async () => {
                const config = {
                    timeout: this.espera,
                    headers: {
                        Accept: 'application/json'
                    }
                }
                try {
                    return await this.$axios.get(
                        this.url + 'cobertura/poblacion/' + poblacion + '/calle/' + calle,
                        config
                    )
                } catch (error) {
                    this.go(
                        'Se ha producido un error. Por favor, inténtelo de nuevo más tarde.',
                        'error'
                    )
                }
            }

            const processListarNumerosCalle = async () => {
                const listarNumerosCalle_result = await golistarNumerosCalle()
                if (listarNumerosCalle_result.data.data.length > 0) {
                    listarNumerosCalle_result.data.data.forEach((element) => {
                        var numeroCalle = {
                            valor: element.numero
                        }

                        this.arrayNumerosCalles.push(numeroCalle)
                    })
                }
            }

            processListarNumerosCalle()
        },
        addZero (i) {
            if (i < 10) {
                i = '0' + i
            }
            return i
        },
        calcularEdad (fecha_nacimiento) {
            var hoy = new Date()
            var cumpleanos = new Date(fecha_nacimiento)
            var edad = hoy.getFullYear() - cumpleanos.getFullYear()
            var m = hoy.getMonth() - cumpleanos.getMonth()
            if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
                edad--
            }
            if (edad < 18) {
                this.go(
                    'Para realizar un pedido es necesario ser mayor de edad.',
                    'error'
                )
                this.bloquearBotonGardarFechaNacemento = true
            } else {
                this.bloquearBotonGardarFechaNacemento = false
            }
        },
        comprobarCobertura () {
            this.$store.dispatch('setdatosClienteValidados', '')
            this.$store.dispatch('setpedidoRealizado', false)
            var direccionFibra = this.arrayDirecciones.filter(
                (direccion) =>
                //    direccion.puerta === this.selectPuerta &&
                    direccion.piso === this.selectPlanta &&
          direccion.escalera === this.selectEscalera
            )

            if (!this.borradoPulsado) {
                if (direccionFibra[0]) {
                    this.$store.commit(
                        'SET_CODIGOCOBERTURAFIBRA',
                        direccionFibra[0].apartment_id
                    )

                    this.apartment_id = direccionFibra[0].apartment_id
                    var order_infoJ = this.$store.getters.getjsonPedido

                    if (!order_infoJ) {
                        var lineaFibra = this.$store.getters.getFibra
                        var date_format = new Date()
                        const mes = date_format.getMonth() + 1

                        const h = this.addZero(date_format.getUTCHours())
                        const m = this.addZero(date_format.getMinutes())
                        const s = this.addZero(date_format.getSeconds())
                        const time = h + ':' + m + ':' + s
                        var fechaFormateada =
              mes +
              '/' +
              date_format.getDate() +
              '/' +
              date_format.getFullYear() +
              ' ' +
              time
                        var lineasJ = []
                        lineaFibra.id_linea = uuid.v1()
                        lineasJ.push(lineaFibra)

                        const pedidoOrder = {
                            lineas: lineasJ
                        }
                        let order_infoJ
                        if (this.$store.getters.getComercial.padre) {
                            order_infoJ = {
                                idDistribuidor: this.$store.getters.getComercial.padre,
                                idHijoDistribuidor: this.$store.getters.getComercial.id,
                                nombreHijoDistribuidor: this.$store.getters.getComercial.email,
                                pedidoOrder: pedidoOrder,
                                estadoOrder: 'pendiente',
                                clienteCreado: 'false',
                                origen: 'comerciales',
                                fecha: fechaFormateada,
                                id_pedido: uuid.v1(),
                                envioFirma: false,
                                fechaEnvioFirma: ''
                            }
                        } else {
                            order_infoJ = {
                                idDistribuidor: this.$store.getters.getComercial.id,
                                pedidoOrder: pedidoOrder,
                                estadoOrder: 'pendiente',
                                clienteCreado: 'false',
                                origen: 'comerciales',
                                fecha: fechaFormateada,
                                id_pedido: uuid.v1(),
                                envioFirma: false,
                                fechaEnvioFirma: ''
                            }
                        }

                        this.$store.dispatch('setjsonPedido', order_infoJ)
                        this.go('Dispone de cobertura de fibra!', 'primary')
                    } else {
                        order_infoJ.pedidoOrder.lineas[0].apartment_id = this.apartment_id

                        this.$store.dispatch('setjsonPedido', order_infoJ)

                        this.go('Dispone de cobertura de fibra!', 'primary')
                    }
                } else {
                    this.go(
                        'No se ha encontrado cobertura de fibra. Envíanos un correo electrónico a comercial@fibra.gal para revisarlo.',
                        'error'
                    )
                }
            }
        },
        reset () {
            this.calleSeleccionada = {}
        },

        comprobarDireccion () {
            if (this.selectNumeroCalle) {
                this.barraLoading = true

                const poblacion = this.calleSeleccionada.poblacion
                    .trim()
                    .replace(/\s+/g, '%20')
                const calle = this.calleSeleccionada.nombre
                    .trim()
                    .replace(/\s+/g, '%20')
                const goComprobarDireccion = async () => {
                    const config = {
                        timeout: this.espera,
                        headers: {
                            Accept: 'application/json'
                        }
                    }
                    try {
                        return await this.$axios.get(
                            this.url +
                'cobertura/poblacion/' +
                poblacion +
                '/calle/' +
                calle +
                '/numero/' +
                this.selectNumeroCalle.valor,
                            config
                        )
                    } catch (error) {
                        this.go(
                            'Se ha producido un error. Por favor, inténtelo de nuevo más tarde.',
                            'error'
                        )
                    }
                }

                const processComprobarDireccion = async () => {
                    const comprobarDireccion_result = await goComprobarDireccion()
                    if (comprobarDireccion_result) {
                        this.arrayDirecciones = comprobarDireccion_result.data.data

                        this.tipoVial = this.arrayDirecciones[0].tipo_vial

                        this.arrayDirecciones.forEach((element) => {
                            element.marcado = false
                        })
                        this.ocultarBotonCalleNumeroFormulario = true
                        this.barraLoading = false
                    } else {
                        this.barraLoading = false
                    }
                }

                processComprobarDireccion()
            }
        },
        buscarCodigoPostal () {
            this.selectNumeroCalle = ''
            this.calleSeleccionada = {}
            this.bloquearSelectorNumero = true
            this.calles = []
            this.arrayPuertas = []
            this.arrayNumerosCalles = []
            this.ocultarBotonCalleNumeroFormulario = false
            this.isDisabledList = true
            const goCodigoPostal = async () => {
                const config = {
                    timeout: this.espera,
                    headers: {
                        Accept: 'application/json'
                    }
                }
                try {
                    return await this.$axios.get(
                        this.url + '/cobertura/cp/' + this.cp,
                        config
                    )
                } catch (error) {
                    this.go(
                        'Se ha producido un error. Por favor, inténtelo de nuevo más tarde.',
                        'error'
                    )
                }
            }

            const processCodigoPostalResult = async () => {
                const codigoPostal_result = await goCodigoPostal()
                if (codigoPostal_result.data.data.length > 0) {
                    codigoPostal_result.data.data.forEach((element) => {
                        var calle = {
                            nombre: element.vial,
                            poblacion: element.poblacion
                        }
                        this.calles.push(calle)
                    })
                    this.ocultarBotonCalleNumeroFormulario = false
                    this.isDisabledList = false
                } else {
                    this.verForm = false
                    this.go(
                        'No disponemos de cobertura de fibra en ese código postal.',
                        'error'
                    )
                    this.selectNumeroCalle = ''
                    this.calleSeleccionada = {}
                    this.bloquearSelectorNumero = true
                    this.ocultarBotonCalleNumeroFormulario = false
                    this.calles.push()
                    this.arrayNumerosCalles = []
                    this.reset()
                    this.isDisabledList = true
                }
            }

            processCodigoPostalResult()
        },
        comprobarCP () {
            if (this.cp.length === 5) {
                this.buscarCodigoPostal()
                this.reset()

                this.calles = []
                this.arrayNumerosCalles = []
            }
        },

        enviarFormulario () {
            this.$store.dispatch('setdatosClienteValidados', false)

            var datePart = this.fecha_nacimiento.match(/\d+/g)
            var year = datePart[0] // get only two digits
            var month = datePart[1]
            var day = datePart[2]
            var fecha_nacimientoFormateada
            fecha_nacimientoFormateada = day + '/' + month + '/' + year
            var direccionFormateada

            const arrayTrocearNombreCalle = this.calleSeleccionada.nombre
                .toLocaleLowerCase()
                .split(' ')
            for (let i = 0; i < arrayTrocearNombreCalle.length; i++) {
                arrayTrocearNombreCalle[i] =
          arrayTrocearNombreCalle[i].charAt(0).toUpperCase() +
          arrayTrocearNombreCalle[i].slice(1)
            }
            const nombreCalleFormateada = arrayTrocearNombreCalle.join(' ')

            direccionFormateada =
        this.tipoVial +
        ' ' +
        nombreCalleFormateada +
        ', ' +
        this.selectNumeroCalle.valor +
        ' Planta ' +
        this.selectPlanta +
        ' P' +
        this.selectPuerta
            const clienteJ = {
                subscriberType: this.subscriberType.id,
                documentType: this.documentType,
                documentoDni: 'sin dni del solicitante',
                empresa: this.empresa,
                fiscalId: this.fiscalId.toUpperCase(),
                contactFiscalId: this.contactFiscalId.toUpperCase(),
                nombre: this.nombre,
                apellidos: this.apellidos,
                calle: direccionFormateada,
                cp: this.cp,
                poblacion: this.poblacion,
                provincia: this.provincia,
                direccionFacturacionProvincia: this.direccionFacturacionProvincia,
                direccionFacturacionPoblacion: this.direccionFacturacionPoblacion,
                direccionFacturacionCp: this.direccionFacturacionCp,
                direccionFacturacionDireccion: this.direccionFacturacionDireccion,
                email: this.email,
                movil: this.movil,
                numeroCuenta: this.numeroCuenta,
                fecha_nacimiento: fecha_nacimientoFormateada,
                nacionalidad: this.nacionalidad,
                documento_name: '',
                documento: ''
            }
            var order_infoJ = this.$store.getters.getjsonPedido
            order_infoJ.pedidoOrder.cliente = clienteJ
            this.$store.dispatch('setjsonPedido', order_infoJ)
            this.$store.dispatch('setdatosClienteValidados', true)
        }
    },
    created () {
        this.$store.commit('SET_CODIGOCOBERTURAFIBRA', '')
    }
}
</script>
